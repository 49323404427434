// extracted by mini-css-extract-plugin
export var content = "statement-block-with-icon-option-module--content--2d063";
export var ctaWrapper = "statement-block-with-icon-option-module--ctaWrapper--fa668";
export var description = "statement-block-with-icon-option-module--description--8475a";
export var ghostedImage = "statement-block-with-icon-option-module--ghostedImage--2e0f3";
export var iconTitleWrapper = "statement-block-with-icon-option-module--iconTitleWrapper--83b7c";
export var iconWrapper = "statement-block-with-icon-option-module--iconWrapper--23c0d";
export var next = "statement-block-with-icon-option-module--next--198e7";
export var prev = "statement-block-with-icon-option-module--prev--8be83";
export var statement = "statement-block-with-icon-option-module--statement--beaaf";
export var statements = "statement-block-with-icon-option-module--statements--095ea";
export var videoContainer = "statement-block-with-icon-option-module--videoContainer--c5d3f";
export var wrapper = "statement-block-with-icon-option-module--wrapper--8dd03";